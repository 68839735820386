import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';

function ReturnCodeRm({handleNext, choice}) {
    const [state, setState] = React.useState({checked: false});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <Typography variant='h4'>Wir haben Ihre verschlüsselte Stimme erhalten und gespeichert.</Typography>
            <Typography variant='h5'>Ihr Prüfcode für die Wahloption {choice} ist korrekt.</Typography>


            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}>
                Weiter
            </Button>

        </div>
    );
}

export default ReturnCodeRm;