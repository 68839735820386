import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, Typography} from '@material-ui/core';

function ReturnCodeRmCv({handleNext, returnCode}) {
    const [state, setState] = React.useState({checked: false});

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <Typography variant='h4'>Stimmerfassung</Typography>
            <Typography variant='h5'>Herzlichen Glückwunsch!</Typography>
            <Typography variant='h5'>Ihre Stimme wurde erfolgreich erfasst.</Typography>
            <Typography variant='h5'>Der Prüfcode (Schritt 4) ist korrekt. Klicken Sie auf Weiter, um mit Schritt 5 und 6 die Wahl abzuschließen.</Typography>



            <Button
                variant="contained"
                color="primary"
                onClick={handleNext}>
                Weiter
            </Button>

        </div>
    );
}

export default ReturnCodeRmCv;