import React from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, FormControl, Input, FormControlLabel, Typography} from "@material-ui/core";

function FinalisationCode({handleNext}) {
    const [state, setState] = React.useState("");

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <Typography variant='h5'>Bitte geben Sei hier den Finalisierungscode ein und klicken Sie auf Weiter</Typography>    
            <FormControl>
                <Input id="finalisation_code" aria-describedby="Finalisierungscode" placeholder="Finalisierungscode" 
                onChange={e => setState({ value: e.target.value })} value={state.value} />
            </FormControl>

            <Button
                variant="contained"
                color="primary"
                disabled={!state.value}
                onClick={handleNext}>
                Weiter
            </Button>
        </div>
    );
}

export default FinalisationCode;