import './App.css';
import "@fontsource/roboto";
import {CssBaseline, Grid} from "@material-ui/core";
import Topnav from "./components/Topnav";
import React from "react";
import Menu from "./components/Menu";
import VotingCode from "./components/VotingCode";
import ReturnCode from "./components/ReturnCode";
import ReturnCodeRm from './components/ReturnCodeRm';
import ReturnCodeRmCv from './components/ReturnCodeRmCv';
import SingleCodeScanner from "./components/SingleCodeScanner";
import FinalisationCode from "./components/FinalisationCode";
import FinalisationCodeRmCv from "./components/FinalisationCodeRmCv";
import passwordOverlay from "./assets/overlay_password.png"
import confirmationCodeOverlay from "./assets/overlay_confirmation.png"
import OptionSelector from "./components/OptionSelector";
import OptionSelectorRm from './components/OptionSelectorRm';

const codeVotingSteps = {
    start: 0,
    scanVotingCode: 1,
    returnCode: 2,
    returnCodeDone: 3,
    scanConfirmationCode: 4,
    finalisationCode: 5,
    finish: 6
};

const improvedOriginalSteps = {
    start: 0,
    password: 1,
    selectOption: 2,
    returnCode: 3,
    returnCodeDone: 4,
    scanConfirmationCode: 5,
    finalisationCode: 6,
    finish: 7
};

function App() {
    const [step, setStep] = React.useState(0);
    const [returnCode, setReturnCode] = React.useState("");
    const [choice, setChoice] = React.useState("");

    const handleNext = () => {
        setStep((prevStep) => prevStep + 1);
    };

    const now = Date.now();
    const limit = new Date(2022, 0, 3, 0, 0, 0, 0);

    return (
        <div>
            <Grid container direction="column">
                <Topnav className="Topnav"/>
                <div className="ContainerStyles">
                {(now < limit) && <div>
                            Studie startet erst am 3.1.2022.
                        </div>} 
                    {(now >= limit) && (window.location.host.split(".")[0] == "bern" || new URLSearchParams(window.location.search).get("type") == "ncv") && <div>
                         {(step === improvedOriginalSteps.start || step === improvedOriginalSteps.returnCodeDone || step === improvedOriginalSteps.finish) &&
                        <Menu step={step} steps={improvedOriginalSteps} handleNext={handleNext}
                              firstButton={"Passwort scannen"} surveyURL={"https://www.soscisurvey.de/codevoting/?type=ncv"}/>}
                        {step === improvedOriginalSteps.password &&
                        <SingleCodeScanner handleNext={handleNext} code={"ebri-esp6-hwrv-8542-mqih"}
                                           overlay={passwordOverlay}/>}
                        {(step === improvedOriginalSteps.selectOption) &&
                        <OptionSelectorRm handleNext={handleNext} setChoice={setChoice}/>}
                        {(step === improvedOriginalSteps.returnCode) &&
                        <ReturnCodeRm handleNext={handleNext} choice={choice}/>}
                        {step === improvedOriginalSteps.scanConfirmationCode &&
                        <SingleCodeScanner handleNext={handleNext} code={"a321-m25n1-ks1p"}
                                           overlay={confirmationCodeOverlay}/>}
                        {step === improvedOriginalSteps.finalisationCode &&
                        <FinalisationCode handleNext={handleNext}/>}
                    </div>}
                    {(now >= limit) && (window.location.host.split(".")[0] == "2021" || new URLSearchParams(window.location.search).get("type") == "ycv") && <div>
                    {(step === codeVotingSteps.start || step === codeVotingSteps.returnCodeDone || step === codeVotingSteps.finish) &&
                        <Menu step={step} steps={codeVotingSteps} handleNext={handleNext}
                              firstButton={"Wahlkarte scannen"} surveyURL={"https://www.soscisurvey.de/codevoting/?type=ycv"}/>}
                        {step === codeVotingSteps.scanVotingCode &&
                        <VotingCode handleNext={handleNext} setReturnCode={setReturnCode}/>}
                        {step === codeVotingSteps.returnCode &&
                        <ReturnCodeRmCv handleNext={handleNext} returnCode={returnCode}/>}
                        {step === codeVotingSteps.scanConfirmationCode &&
                        <SingleCodeScanner handleNext={handleNext} code={"a321-m25n1-ks1p"}
                                           overlay={confirmationCodeOverlay}/>}
                        {step === codeVotingSteps.finalisationCode &&
                        <FinalisationCodeRmCv handleNext={handleNext}/>}
                    </div>}
                </div>
            </Grid>
            <CssBaseline/>
        </div>
    );
}

export default App;
